import {
  ApartmentOutlined,
  BankOutlined,
  FileDoneOutlined,
  UserOutlined,
  HomeOutlined,
  WalletOutlined,
  ShoppingOutlined,
  PictureOutlined,
  SettingOutlined,
  MehOutlined,
  SkinOutlined,
  ScissorOutlined,
  ShopOutlined,
  MoneyCollectOutlined,
  GiftOutlined,
  HddOutlined,
  TagsOutlined,
  RedEnvelopeOutlined,
} from "@ant-design/icons";
import { MerchantTypeEnum, RouteActionEnum } from "../enums/common";

export type LocalMenuType = {
  key: string;
  icon?: React.ReactNode;
  label?: React.ReactNode;
  path?: string | string[];
  parentmenuid?: string;
  level: number;
  children?: LocalMenuType[];
  menucode: string;
  includemenucodes?: string[];
  hidden?: boolean;
};

const menu: LocalMenuType[] = [
  {
    key: "123163868740050944",
    label: "商家管理",
    menucode: "ENTERPRISE_MANAGEMENT",
    parentmenuid: "0",
    level: 1,
    children: [
      {
        key: "153002161415401472",
        label: "商家列表",
        menucode: "MERCHANT_LIST",
        parentmenuid: "123163868740050944",
        level: 2,
        icon: <ShopOutlined />,
        path: "/home/merchants",
        children: [
          {
            key: "153002442131779584",
            label: "新增商家",
            menucode: "ADD_MERCHANT",
            path: "/home/merchants/create",
            parentmenuid: "153002161415401472",
            level: 3,
          },
          {
            key: "153002535325020160",
            label: "编辑商家",
            menucode: "EDIT_MERCHANT",
            path: "/home/merchants/edit/:id",
            parentmenuid: "153002161415401472",
            level: 3,
          },
          {
            key: "153002363165618176",
            label: "查看商家列表",
            menucode: "VIEW_MERCHANT_LIST",
            parentmenuid: "153002161415401472",
            level: 3,
          },
          {
            key: "184152726429655040",
            label: "结算设置",
            menucode: "SETTLEMENT_SETTING",
            parentmenuid: "153002161415401472",
            level: 3,
          },
          {
            key: "184153838670995456",
            label: "认证信息",
            menucode: "VIEW_MERCHANT_LIST",
            parentmenuid: "153002161415401472",
            level: 3,
            path: "/home/merchants/authentication/:id",
          },
        ],
      },
      {
        key: "123177315703840768",
        label: "商家信息",
        menucode: "COMMERCIAL_MANAGEMENT",
        parentmenuid: "123163868740050944",
        level: 2,
        icon: <HomeOutlined />,
        path: "/home/merchant",
        children: [
          {
            key: "126179254588428289",
            label: "查看商家信息",
            menucode: "VIEW_CURRENT_MERCHANT",
            parentmenuid: "123177315703840768",
            level: 3,
            children: [],
          },
          {
            key: "184153838670995456",
            label: "认证信息",
            menucode: "VIEW_CURRENT_MERCHANT",
            parentmenuid: "123177315703840768",
            level: 3,
            path: "/home/merchant/authentication/:id",
          },
        ],
      },
      {
        key: "123178668505292800",
        label: "门店管理",
        icon: <BankOutlined />,
        path: "/home/store",
        menucode: "STORE_MANAGEMENT",
        parentmenuid: "123163868740050944",
        level: 2,
        children: [
          {
            key: "5",
            label: "新增门店",
            menucode: "CREATE_STORE",
            path: "/home/store/create",
            parentmenuid: "123178668505292800",
            level: 3,
          },
          {
            key: "6",
            label: "查看门店",
            menucode: "VIEW_STORE_LIST",
            parentmenuid: "123178668505292800",
            level: 3,
          },
          {
            key: "7",
            label: "编辑门店",
            menucode: "EDIT_STORE",
            path: "/home/store/edit/:id",
            parentmenuid: "123178668505292800",
            level: 3,
          },
          {
            key: "8",
            label: "启用/禁用门店",
            menucode: "ENABLE_OR_DISABLE_STORE",
            parentmenuid: "123178668505292800",
            level: 3,
          },
        ],
      },
      {
        key: "132688310482554881",
        label: "设备管理",
        icon: <HddOutlined />,
        path: "/home/device",
        menucode: "DEVICE_MANAGEMENT",
        parentmenuid: "123163868740050944",
        level: 2,
        children: [
          {
            key: "132689099489857537",
            label: "查看设备列表",
            menucode: "DEVICE_LIST",
            parentmenuid: "132688310482554881",
            level: 3,
          },
          {
            key: "132690127383744512",
            label: "编辑设备",
            menucode: "EDIT_DEVICE",
            path: "/home/device/edit/:id",
            parentmenuid: "132688310482554881",
            level: 3,
          },
          {
            key: "181964306168958976",
            label: "删除设备",
            menucode: "DELETE_DEVICE",
            parentmenuid: "132688310482554881",
            level: 3,
          },
          {
            key: "153670022995980289",
            label: "修改密码",
            menucode: "MODIFY_PASSWORD",
            parentmenuid: "132688310482554881",
            level: 3,
          },
          {
            key: "158791069027217409",
            label: "设置打印机纸张数量",
            menucode: "UPDATE_PRINT_PAPER_COUNT",
            parentmenuid: "132688310482554881",
            level: 3,
          },
        ],
      },
      {
        key: "145719608082792448",
        label: "主题管理",
        menucode: "THEME_MANAGER",
        icon: <SkinOutlined />,
        parentmenuid: "123163868740050944",
        path: "/home/themeManage",
        level: 2,
        children: [
          {
            key: "145720898972123136",
            label: "新增主题",
            menucode: "CREATE_THEME",
            path: "/home/themeManage/create",
            parentmenuid: "145719608082792448",
            level: 3,
          },
          {
            key: "145721330318540800",
            label: "修改主题",
            menucode: "UPDATE_THEME",
            path: "/home/themeManage/edit/:id",
            parentmenuid: "145719608082792448",
            level: 3,
          },
          {
            key: "145721523604652032",
            label: "查看主题",
            menucode: "VIEW_THEME",
            path: "/home/themeManage",
            parentmenuid: "145719608082792448",
            level: 3,
          },
        ],
      },
      {
        key: "145755812526645248",
        label: "装修模板管理",
        menucode: "DECORATION_TEMPLATE_MANAGER",
        icon: <ScissorOutlined />,
        parentmenuid: "123163868740050944",
        path: "/home/decoration",
        level: 2,
        children: [
          {
            key: "145756949195612160",
            label: "装修模板列表",
            menucode: "DECORATION_TEMPLATE_LIST",
            parentmenuid: "145755812526645248",
            level: 3,
          },
          {
            key: "145756538724245504",
            label: "新增装修模板",
            menucode: "ADD_DECORATION_TEMPLATE",
            parentmenuid: "145755812526645248",
            level: 3,
            path: "/home/decoration/create",
          },
          {
            key: "145756634174021632",
            label: "编辑装修模板",
            menucode: "EDIT_DECORATION_TEMPLATE",
            parentmenuid: "145755812526645248",
            level: 3,
            path: "/home/decoration/edit/:id",
          },
        ],
      },
    ],
  },
  {
    key: "123166073597583360",
    label: "商品管理",
    menucode: "GOODS_MANAGEMENT",
    parentmenuid: "0",
    level: 1,
    children: [
      {
        key: "123181582586077184",
        label: "商品列表",
        menucode: "GOODS_LIST",
        parentmenuid: "123166073597583360",
        level: 2,
        path: "/home/goods",
        icon: <ShoppingOutlined />,
        children: [
          {
            key: "126166853369483264",
            label: "查看商品",
            menucode: "VIEW_GOODS_LIST",
            parentmenuid: "123181582586077184",
            level: 3,
          },
          {
            key: "126167145183989760",
            label: "新建商品",
            menucode: "CREATE_GOODS",
            parentmenuid: "123181582586077184",
            level: 3,
            path: "/home/goods/goodsInfoPage",
          },
          {
            key: "126167618939015168",
            label: "编辑商品",
            menucode: "EDIT_GOODS",
            parentmenuid: "123181582586077184",
            level: 3,
            path: [
              "/home/goods/goodsInfoPage/:id",
              "/home/goods/goodsInfoPage/:id/:detail",
            ],
          },
          {
            key: "126168102542266368",
            label: "商品上下架",
            menucode: "ENALBE_DISABLE_GOODS",
            parentmenuid: "123181582586077184",
            level: 3,
          },
        ],
      },
      {
        key: "123181720268300288",
        label: "模板列表",
        menucode: "TEMPLATE_CATEGORY_LIST",
        parentmenuid: "123166073597583360",
        level: 2,
        path: "/home/template",
        icon: <PictureOutlined />,
        children: [
          {
            key: "126179048987840512",
            label: "查看模板库",
            menucode: "VIEW_TEMPLATE_CATEGORY_LIST",
            parentmenuid: "123181720268300288",
            level: 3,
          },
          {
            key: "126179165589491712",
            label: "创建模板库",
            menucode: "CREATE_TEMPLATE_CATEGORY",
            parentmenuid: "123181720268300288",
            level: 3,
            path: "/home/template/templateInfoPage",
          },
          {
            key: "126179254588428288",
            label: "编辑模板库",
            menucode: "EDIT_TEMPLATE_CATEGORY",
            parentmenuid: "123181720268300288",
            level: 3,
            path: "/home/template/templateInfoPage/:id",
          },
          {
            key: "11",
            label: "模板设置",
            menucode: "TEMPLATE_SETTING",
            parentmenuid: "123181720268300288",
            level: 3,
            path: "/home/template/templateSettingPage/:id/:type",
          },
        ],
      },
      {
        key: "145721727305220096",
        label: "相框管理",
        menucode: "BOOTH_MANAGE",
        parentmenuid: "123166073597583360",
        level: 2,
        path: "/home/purikuraFrame",
        icon: <MehOutlined />,
        children: [
          {
            key: "145722090020241408",
            label: "上传相框",
            menucode: "BOOTH_FILE_UPLOAD",
            parentmenuid: "145721727305220096",
            level: 3,
            path: "/home/purikuraFrame/create",
          },
          {
            key: "145722599938555904",
            label: "编辑相框",
            menucode: "EDIT_BOOTH",
            parentmenuid: "145721727305220096",
            level: 3,
            path: "/home/purikuraFrame/edit/:id",
          },
          {
            key: "145722233230557184",
            label: "查看大头贴",
            menucode: "VIEW_BOOTH",
            parentmenuid: "145721727305220096",
            level: 3,
          },
          {
            key: "153123230338981889",
            label: "启/禁用大头贴相框",
            menucode: "ENABLE_OR_DISABLE_BOOTH",
            parentmenuid: "145721727305220096",
            level: 3,
          },
        ],
      },
    ],
  },
  {
    key: "123166137732685824",
    label: "订单管理",
    menucode: "ORDER_MANAGEMENT",
    parentmenuid: "0",
    level: 1,
    children: [
      {
        key: "123182181734014976",
        label: "智拍订单",
        menucode: "ORDER_LIST",
        path: "/home/order",
        parentmenuid: "123166137732685824",
        level: 2,
        icon: <WalletOutlined />,
        children: [
          {
            key: "126433886531022848",
            label: "订单详情",
            menucode: "ORDER_DETAIL",
            path: "/home/order/detail/:id",
            parentmenuid: "123182181734014976",
            level: 3,
          },
          {
            key: "126433988981092352",
            label: "取消订单",
            menucode: "CANCEL_ORDER",
            parentmenuid: "123182181734014976",
            level: 3,
          },
          {
            key: "126433770168446976",
            label: "查看订单",
            menucode: "VIEW_ORDER_LIST_V",
            parentmenuid: "123182181734014976",
            level: 3,
          },
          {
            key: "126434182627913728",
            label: "订单收款表",
            menucode: "ORDER_COLLECTION_LIST",
            parentmenuid: "123182181734014976",
            level: 3,
          },
          {
            key: "126434274441228288",
            label: "查看照片",
            menucode: "ORDER_PICTURES_DETAIL",
            parentmenuid: "123182181734014976",
            level: 3,
          },
          {
            key: "126434373955284992",
            label: "退款详情",
            menucode: "REFUND_DETAIL",
            parentmenuid: "123182181734014976",
            level: 3,
          },
        ],
      },
      {
        key: "152996220917788672",
        label: "权益订单",
        menucode: "EQUITY_ORDER",
        path: "/home/equity",
        parentmenuid: "123166137732685824",
        level: 2,
        icon: <WalletOutlined />,
        children: [
          {
            key: "152996684325466112",
            label: "查看权益订单列表",
            menucode: "VIEW_EQUITY_ORDER_LIST",
            path: "/home/equity",
            parentmenuid: "152996220917788672",
            level: 3,
          },
          {
            key: "152996878022619136",
            label: "查看权益订单详情",
            menucode: "VIEW_EQUITY_ORDER_DETAIL",
            parentmenuid: "152996220917788672",
            level: 3,
          },
        ],
      },
      {
        key: "158801634485346304",
        label: "照片冲印",
        menucode: "PRINT_ORDER_MANAGER",
        path: "/home/print",
        parentmenuid: "123166137732685824",
        level: 2,
        icon: <PictureOutlined />,
        children: [
          {
            key: "158802031778209792",
            label: "查看冲印订单列表",
            menucode: "PRINT_ORDER_LIST",
            path: "/home/print",
            parentmenuid: "158801634485346304",
            level: 3,
          },
        ],
      },
      {
        key: "208396674323116032",
        label: "团购券核销",
        menucode: "VERIFY_DETAIL",
        path: "/home/groupPurchase",
        parentmenuid: "123166137732685824",
        level: 2,
        icon: <RedEnvelopeOutlined />,
        children: [
          {
            key: "158802031778209792",
            label: "查看团购券列表",
            menucode: "VERIFY_DETAIL",
            path: "/home/groupPurchase",
            parentmenuid: "208396674323116032",
            level: 3,
          },
        ],
      },
    ],
  },
  {
    key: "123166317047570432",
    label: "营销活动",
    menucode: "MARKETING_ACTIVITY",
    parentmenuid: "0",
    level: 1,
    children: [
      {
        key: "123182345961988096",
        label: "活动列表",
        menucode: "ACTIVITY_LIST",
        icon: <FileDoneOutlined />,
        parentmenuid: "123166317047570432",
        level: 2,
        path: "/home/marketing",
        children: [
          {
            key: "152996492692246528",
            label: "新增活动",
            menucode: "CREATE_ACTIVITY",
            parentmenuid: "123182345961988096",
            level: 3,
            path: "/home/marketing/create/:activityType",
          },
          {
            key: "152996699731480576",
            label: "编辑活动",
            menucode: "EDIT_ACTIVITY",
            parentmenuid: "123182345961988096",
            level: 3,
            path: "/home/marketing/edit/:activityType/:id",
          },
          {
            key: "152995111814123520",
            label: "查看首页轮播图活动",
            menucode: "VIEW_CAROUSEL_CHART_ACTIVITY",
            parentmenuid: "123182345961988096",
            level: 3,
          },
          {
            key: "152995374843121664",
            label: "查看走一走活动",
            menucode: "VIEW_NEARBY_ACTIVITY",
            parentmenuid: "123182345961988096",
            level: 3,
          },
          {
            key: "152995844055715840",
            label: "活动码",
            menucode: "ACTIVITY_MP_CODE",
            parentmenuid: "123182345961988096",
            level: 3,
          },
          {
            key: "154752063296589824",
            label: "修改活动排序",
            menucode: "MODIFY_ACTIVITY_SORT",
            parentmenuid: "123182345961988096",
            level: 3,
          },
        ],
      },
      {
        key: "152997714992123904",
        label: "优惠券管理",
        menucode: "COUPON",
        icon: <MoneyCollectOutlined />,
        parentmenuid: "123166317047570432",
        level: 2,
        path: "/home/coupon",
        children: [
          {
            key: "152997880872652800",
            label: "查看优惠券列表",
            menucode: "VIEW_COUPON_LIST",
            parentmenuid: "152997714992123904",
            level: 3,
          },
          {
            key: "152997983234641920",
            label: "新增优惠券",
            menucode: "CREATE_COUPON",
            parentmenuid: "152997714992123904",
            level: 3,
            path: "/home/coupon/create",
          },
          {
            key: "152998055288590336",
            label: "编辑优惠券",
            menucode: "EDIT_COUPON",
            parentmenuid: "152997714992123904",
            level: 3,
            path: "/home/coupon/edit/:id",
          },
          {
            key: "155176657412960256",
            label: "查看优惠券",
            menucode: "VIEW_COUPON",
            parentmenuid: "152997714992123904",
            level: 3,
            path: "/home/coupon/:detail/:id",
          },
          {
            key: "152998198574403584",
            label: "失效",
            menucode: "COUPON_INVALID",
            parentmenuid: "152997714992123904",
            level: 3,
          },
        ],
      },
      {
        key: "152997214884286464",
        label: "拍摄有礼",
        menucode: "PAYMENT_DISCOUNTS",
        icon: <GiftOutlined />,
        parentmenuid: "123166317047570432",
        level: 2,
        path: "/home/payGifts/edit",
        children: [
          {
            key: "152997477955227648",
            label: "编辑支付有礼活动",
            menucode: "EDIT_PAYMENT_DISCOUNTS_MARKETING",
            parentmenuid: "152997214884286464",
            level: 3,
          },
        ],
      },
    ],
  },
  {
    key: "132942548948312064",
    label: "系统管理",
    menucode: "SYSTEM_MANAGEMENT",
    parentmenuid: "0",
    level: 1,
    children: [
      {
        key: "132943621062746112",
        label: "系统设置",
        menucode: "SYS_SETTING",
        icon: <SettingOutlined />,
        parentmenuid: "132942548948312064",
        level: 2,
        path: "/home/systemConfig",
        children: [
          {
            key: "1231231233213",
            label: "系统设置",
            menucode: "SYS_SETTING_THIRD",
            parentmenuid: "132943621062746112",
            level: 3,
          },
        ],
      },
      {
        key: "209859976696172544",
        label: "设备版本管理",
        menucode: "DEVICE_VERSION_MANAGE",
        icon: <TagsOutlined />,
        parentmenuid: "132942548948312064",
        level: 2,
        path: "/home/deviceVersion",
        children: [
          {
            key: "209869154701643776",
            label: "查看设备版本",
            menucode: "VIEW_DEVICE_VERSION",
            parentmenuid: "209859976696172544",
            level: 3,
          },
          {
            key: "209860419639840768",
            label: "下发到设备",
            menucode: "ISSUE_TO_DEVICE",
            parentmenuid: "209859976696172544",
            level: 3,
          },
          {
            key: "209860200063832064",
            label: "编辑设备版本",
            menucode: "UPDATE_DEVICE_VERSION",
            parentmenuid: "209859976696172544",
            path: "/home/deviceVersion/edit/:id",
            level: 3,
          },
          {
            key: "209860105121566720",
            label: "新增设备版本",
            menucode: "CREATE_DEVICE_VERSION",
            parentmenuid: "209859976696172544",
            path: "/home/deviceVersion/create",
            level: 3,
          },
        ],
      },
      {
        key: "123179642225549312",
        label: "员工管理",
        menucode: "STAFF_MANAGEMENT",
        parentmenuid: "132942548948312064",
        path: "/home/staff",
        icon: <UserOutlined />,
        level: 2,
        children: [
          {
            key: "123290649178488832",
            label: "查看平台员工详情",
            menucode: "PLATFORM_STAFF_DETAIL",
            parentmenuid: "123179642225549312",
            level: 3,
          },
          {
            key: "123523005697077248",
            label: "新增平台员工",
            menucode: "CREATE_PLATFORM_STAFF",
            parentmenuid: "123179642225549312",
            path: `/home/staff/${MerchantTypeEnum.PLATFORM}/${RouteActionEnum.CREATE}`,
            level: 3,
          },
          {
            key: "123523231971389440",
            label: "编辑平台员工",
            menucode: "EDIT__PLATFORM_STAFF",
            parentmenuid: "123179642225549312",
            path: `/home/staff/${MerchantTypeEnum.PLATFORM}/${RouteActionEnum.EDIT}/:id`,
            level: 3,
          },
          {
            key: "123288508481224704",
            label: "查看平台员工列表",
            menucode: "PLATFORM_STAFF_LIST",
            parentmenuid: "123179642225549312",
            level: 3,
          },
          {
            key: "123527494537678848",
            label: "启/禁用平台员工",
            menucode: "UPDATE_STATUS_PLATFORM_STAFF",
            parentmenuid: "123179642225549312",
            level: 3,
          },
          {
            key: "152999548856856576",
            label: "查看商家员工列表",
            menucode: "MERCHANT_STAFF_LIST",
            parentmenuid: "123179642225549312",
            level: 3,
          },
          {
            key: "152999659351601152",
            label: "查看商家员工详情",
            menucode: "MERCHANT_STAFF_DETAIL",
            parentmenuid: "123179642225549312",
            level: 3,
          },
          {
            key: "152999896682098688",
            label: "新增商家员工",
            menucode: "CREATE_MERCHANT_STAFF",
            parentmenuid: "123179642225549312",
            path: `/home/staff/${MerchantTypeEnum.SELLER}/${RouteActionEnum.CREATE}`,
            level: 3,
          },
          {
            key: "153000086696652800",
            label: "编辑商家员工",
            menucode: "EDIT_MERCHANT_STAFF",
            parentmenuid: "123179642225549312",
            path: `/home/staff/${MerchantTypeEnum.SELLER}/${RouteActionEnum.EDIT}/:id`,
            level: 3,
          },
          {
            key: "153000230338981888",
            label: "启/禁用商家员工",
            menucode: "UPDATE_STATUS_MERCHANT_STAFF",
            parentmenuid: "123179642225549312",
            level: 3,
          },
        ],
      },
      {
        key: "123179743748677632",
        label: "角色管理",
        menucode: "ROLE_MANAGEMENT",
        icon: <ApartmentOutlined />,
        parentmenuid: "132942548948312064",
        path: "/home/role",
        level: 2,
        children: [
          {
            key: "123586750360150016",
            label: "查看平台角色详情",
            menucode: "PLATFORM_ROLE_DETAIL",
            parentmenuid: "123179743748677632",
            level: 3,
            path: `/home/role/${MerchantTypeEnum.PLATFORM}/${RouteActionEnum.DETAIL}/:id`,
          },
          {
            key: "123586549905973248",
            label: "查看平台角色列表",
            menucode: "PLATFORM_ROLE_LIST",
            parentmenuid: "123179743748677632",
            level: 3,
          },
          {
            key: "153005607117942784",
            label: "新增平台角色",
            menucode: "CREATE_PLATFORM_ROLE",
            parentmenuid: "123179743748677632",
            level: 3,
            path: `/home/role/${MerchantTypeEnum.PLATFORM}/${RouteActionEnum.CREATE}`,
          },
          {
            key: "153006619601956864",
            label: "新增商户角色",
            menucode: "CREATE_MERCHANT_ROLE",
            parentmenuid: "123179743748677632",
            level: 3,
            path: `/home/role/${MerchantTypeEnum.SELLER}/${RouteActionEnum.CREATE}`,
          },
          {
            key: "153006385022922752",
            label: "查看商户角色列表",
            menucode: "MERCHANT_ROLE_LIST",
            parentmenuid: "123179743748677632",
            level: 3,
          },
          {
            key: "153006007044829184",
            label: "删除平台角色",
            menucode: "DELETE_PLATFORM_ROLE",
            parentmenuid: "123179743748677632",
            level: 3,
          },
          {
            key: "153006755728093184",
            label: "删除商户角色",
            menucode: "DELETE_MERCHANT_ROLE",
            parentmenuid: "123179743748677632",
            level: 3,
          },
          {
            key: "153006455696945152",
            label: "查看商户角色详情",
            menucode: "MERCHANT_ROLE_DETAIL",
            parentmenuid: "123179743748677632",
            level: 3,
            path: `/home/role/${MerchantTypeEnum.SELLER}/${RouteActionEnum.DETAIL}/:id`,
          },
          {
            key: "153005869253554176",
            label: "编辑平台角色",
            menucode: "EDIT_PLATFORM_ROLE",
            parentmenuid: "123179743748677632",
            level: 3,
            path: `/home/role/${MerchantTypeEnum.PLATFORM}/${RouteActionEnum.EDIT}/:id`,
          },
          {
            key: "153006696328359936",
            label: "编辑商户角色",
            menucode: "EDIT_MERCHANT_ROLE",
            parentmenuid: "123179743748677632",
            level: 3,
            path: `/home/role/${MerchantTypeEnum.SELLER}/${RouteActionEnum.EDIT}/:id`,
          },
        ],
      },
    ],
  },
];

export default menu;
