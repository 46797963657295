export enum IDPhotoBackgroundColor {
  BLUE = 1,
  WHITE = 2,
  RED = 3,
}

export const ID_PHOTO_BACKGROUND_COLOR_OPTIONS = [
  {
    label: "蓝色",
    value: IDPhotoBackgroundColor.BLUE,
  },
  {
    label: "白色",
    value: IDPhotoBackgroundColor.WHITE,
  },
  {
    label: "红色",
    value: IDPhotoBackgroundColor.RED,
  },
];
