export enum FrameGridTypeEnum {
  ONE_BOX = 1,
  TWO_BOX = 2,
  FOUR_BOX = 4,
  SIX_BOX = 6,
  EIGHT_BOX = 8,
  NINE_BOX = 9,
}
export enum PhotoFrameTypeEnum {
  PURIKURA = 1,
  IDENTIFICATION_PHOTO = 2,
  PORTRAIT_PHOTO = 3,
}

export enum FrameScaleEnum {
  FOUR_TO_THREE = 1,
  THREE_TO_FOUR = 2,
  FIVE_TO_SEVEN = 3,
  FIFTY_THREE_TO_THIRTY_FIVE = 4,
}

export const FRAME_GRID_ALL_TYPE_OPTIONS = [
  { value: FrameGridTypeEnum.ONE_BOX, label: "一宫格" },
  { value: FrameGridTypeEnum.TWO_BOX, label: "二宫格" },
  { value: FrameGridTypeEnum.FOUR_BOX, label: "四宫格" },
  { value: FrameGridTypeEnum.SIX_BOX, label: "六宫格" },
  { value: FrameGridTypeEnum.EIGHT_BOX, label: "八宫格" },
  { value: FrameGridTypeEnum.NINE_BOX, label: "九宫格" },
];

export const FRAME_GRID_TYPE_OPTIONS = {
  [PhotoFrameTypeEnum.PURIKURA]: [
    { value: FrameGridTypeEnum.ONE_BOX, label: "一宫格" },
    { value: FrameGridTypeEnum.FOUR_BOX, label: "四宫格" },
    { value: FrameGridTypeEnum.SIX_BOX, label: "六宫格" },
    { value: FrameGridTypeEnum.EIGHT_BOX, label: "八宫格" },
  ],
  [PhotoFrameTypeEnum.PORTRAIT_PHOTO]: [
    { value: FrameGridTypeEnum.ONE_BOX, label: "一宫格" },
    { value: FrameGridTypeEnum.TWO_BOX, label: "二宫格" },
    { value: FrameGridTypeEnum.FOUR_BOX, label: "四宫格" },
    { value: FrameGridTypeEnum.SIX_BOX, label: "六宫格" },
  ],
  [PhotoFrameTypeEnum.IDENTIFICATION_PHOTO]: [
    { value: FrameGridTypeEnum.FOUR_BOX, label: "四宫格" },
    { value: FrameGridTypeEnum.SIX_BOX, label: "六宫格" },
    { value: FrameGridTypeEnum.EIGHT_BOX, label: "八宫格" },
    { value: FrameGridTypeEnum.NINE_BOX, label: "九宫格" },
  ],
};

export const PHOTO_FRAME_TYPE_OPTIONS = [
  { value: PhotoFrameTypeEnum.PURIKURA, label: "大头贴" },
  { value: PhotoFrameTypeEnum.IDENTIFICATION_PHOTO, label: "证件照" },
  { value: PhotoFrameTypeEnum.PORTRAIT_PHOTO, label: "肖像照" },
];
